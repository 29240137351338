import React, {useEffect, useState} from 'react';
import "./introduction.css";
import {ReactComponent as CoverPage} from "../../images/coverPage.svg";
import {ReactComponent as Project} from "../../images/MemoryOnProject.svg";
import {ReactComponent as Generations} from "../../images/generations.svg";
import {ReactComponent as GenTriads} from "../../images/genTriads.svg";
import "../../images/finalSketch.jpg";
import Carousel from "react-bootstrap/Carousel";
import {Image} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
function SlideCarousel({idx}){
    const navigate = useNavigate()
    const [index, setIndex] = useState(idx)
    useEffect(()=>{
        setIndex(idx)
    },[idx])

    const handleSelect = (selectedIndex, e) => {
        navigate(`/`, {state: selectedIndex})
    };
    return(
    <Carousel variant={"dark"} interval={null} activeIndex={index} wrap={false} onSelect={handleSelect}>
        <Carousel.Item>
            <CoverPage/>
        </Carousel.Item>
        <Carousel.Item>
            <Project/>
        </Carousel.Item>
        <Carousel.Item>
            <Generations/>
        </Carousel.Item>
        <Carousel.Item>
            <GenTriads/>
        </Carousel.Item>
        <Carousel.Item>
            <Image src={require("../../images/finalSketch.jpg")} style={{width:"100%"}}/>
        </Carousel.Item>
    </Carousel>
    );
}

export default SlideCarousel;