import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Container, Row, Col, OverlayTrigger, Tooltip, Table, Modal, Form} from "react-bootstrap";
import './header.css'
import {useEffect, useState, useRef} from "react";
import { FaUser, FaArrowRight, FaHome, FaComment} from "react-icons/fa";
import Axios from 'axios';

function Header(){
    const navigate = useNavigate();
    const {state} = useLocation()
    const profile = state.profile
    const [header, setHeader] = useState(state.profile.headers[state.header]);

    //get comments
    const [comments, setComments]= useState([])
    useEffect(() => {
        Axios.get(`/api/comments/${profile.profile_id}/${header.header_id}`)
            .then(response => {
                setComments(response.data.comments)
            });
    }, []);



    // boolean to determine if next button should be shown
    const notLastHeader = (header.header_id < profile.headers.length - 1)

    //comment button
    const [textDone, setTextDone] = useState(false);
    // const [overlayOpen, setOverlayOpen]  = useState(true);
    const refLink = useRef(null);


    //modal
    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    //submit form
    const [formData, setFormData] = useState(null)
    const handleChange = (event) => {
        setFormData(event.target.value)
    }
    function handleFormSubmit(){
        Axios.post('api/comments', {"text": formData, "profile_id": profile.profile_id, "header_id":header.header_id });
        setModalShow(false);
    }

    //typewriter effect
    const [aText, setaText] = useState(header.text);
    const iSpeed = 60; // time delay of print out (55 - 70 for readable text)
    let iTextPos = 0; // initialise text position
    let sContents = ''; // initialise contents variable

    function typewriter() {
        sContents = ' ';
        const destination = document.getElementById("typedtext");
        destination.innerHTML = sContents + aText.substring(0, iTextPos);
        window.scrollTo(0, document.body.scrollHeight);
        if (iTextPos++ !== aText.length) {
            setTimeout(typewriter, iSpeed);
        }
        else{
            setTextDone(true)
        }
    }

    //listing aText as a dependency here makes typewriter function wait for new header when nextHeader
    useEffect(() =>{
        typewriter()
    },[aText])
    function nextHeader(){
        setTextDone(false)
        //setting header here is what makes the title change
        setHeader(profile.headers[state.header + 1])
        //setting aText here is what makes text change
        setaText(profile.headers[state.header + 1].text)
        navigate(`/profile/${profile.profile_id}/header/${header.header_id + 1}`, { state: {profile: profile, header: header.header_id + 1}})
    }
    function returnToProfile(){
        navigate(`/profile/${profile.profile_id}`, { state: profile});
    }
    const silentTriad = ["G.I. Generation", "Silent Generation", "Baby Boomer Generation"]
    const handleHomeClick = () =>{
        if(silentTriad.includes(profile.generation)){
            navigate('/silent')
        }
        else{
            navigate('/millennial')
        }
    }

    return(
        <Container>
            <Row>
                <h1 style={{color:"#6f92ba"}}>{header.title}</h1>
                <h2 style={{color:"#6f92ba", width:"90%"}} id="typedtext"/>
            </Row>
            {/*BUTTONS*/}
            <Col className={"fixed-button"} ref={refLink}>
                <>
                    <Row>
                        {textDone &&
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Comments
                                </Tooltip>
                            }
                            placement="left"
                        >
                            <Button className={"btn-outline w-75"} variant="color" style={{marginLeft:"0%", marginBottom:"10px"}}
                                    onClick={handleShow}
                            >
                                <FaComment/>
                            </Button>
                        </OverlayTrigger>
                        }
                    </Row>
                    <Row>
                        { notLastHeader && textDone &&
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Go to next excerpt
                                </Tooltip>
                            }
                            placement="left"
                        >
                            <Button className={"btn-outline w-75"} variant="color" style={{marginBottom:"10px",marginLeft: "0%"}}
                                    onClick={() => nextHeader()}>
                                <FaArrowRight/>
                            </Button>
                        </OverlayTrigger>
                        }
                    </Row>
                    <Row>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Return to profile
                                </Tooltip>
                            }
                            placement="left"
                        >
                            <Button className={"btn-outline w-75"} variant="color" style={{ marginLeft:"0%"}} onClick={() => returnToProfile()}>
                                <FaUser/>
                            </Button>
                        </OverlayTrigger>
                    </Row>
                    <Row>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Return to triad
                                </Tooltip>
                            }
                            placement="left"
                        >
                            <Button className={"btn-outline w-75"} variant="color" style={{marginTop:"10px", marginLeft:"0%"}} onClick={handleHomeClick}>
                                <FaHome/>
                            </Button>
                        </OverlayTrigger>
                    </Row>
                </>
            </Col>
            {/*MODAL*/}
            {comments.length > 0 ?
                <Modal
                    size={"xl"}
                    show={modalShow}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{color:"black"}}>Readers' Comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {comments.map((comment, i) => (
                                <Table bordered hover>
                                    <tbody>
                                    <tr key={i}>
                                        <td>{comment.text}
                                            <p className={"text-end"} style={{fontSize:"10px", color:'gray'}}>
                                                {new Date(comment.date).getMonth() + '/' + new Date(comment.date).getDate() + '/' + new Date(comment.date).getFullYear()}
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            )
                        )}

                    </Modal.Body>
                    <Modal.Body style={{height:"350px"}}>
                        <Modal.Title style={{color:"black"}}>Submit a comment</Modal.Title>
                        <Form id={"commentForm"}>
                            <Form.Control as={"textarea"} rows={"3"} onChange={handleChange}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="color" form="commentForm"  onClick={handleFormSubmit}>Submit</Button>
                    </Modal.Footer>
                </Modal>
                : <Modal
                    size={"xl"}
                    show={modalShow}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    style={{color:"#EEE"}}
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{color:"black"}}>Submit a comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id={"commentForm"}>
                            <Form.Control as={"textarea"} rows={"3"} onChange={handleChange}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="color" form="commentForm"  onClick={handleFormSubmit}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            }

        </Container>

    )
}

export default Header
