import React, {useEffect, useState} from "react";
import Axios from "axios";
import NavBar from "../../components/NavBar/NavBar";
import {Container, Row} from "react-bootstrap";
import GenerationCard from "../../components/GenerationCard/GenerationCard";
import './SilentTriad.css'

function SilentTriad(){
    const [profiles, setProfiles]= useState([])
    useEffect(() => {
        Axios.get('/api/profiles')
            .then(response => {
                setProfiles(response.data.profiles)
            });
    }, []);
    return (
        <div>
            <NavBar/>
            <Container>
                <Row className="flex-container top-buffer">
                    {profiles.map((profile, i) =>  (
                        i < 3 &&
                        <GenerationCard key={i} profiles={profile}/>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default SilentTriad