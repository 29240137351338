import React from 'react';
import {Navbar, NavDropdown, Row, Nav, Container} from 'react-bootstrap';
import './navbar.css'
import {useNavigate} from "react-router-dom";

const NavBar = () =>{
    const navigate = useNavigate()
    const handleClick = (slideNum) =>{
        navigate(`/`, {state: slideNum})
    }

    return(
    <Row style={{marginBottom:"0%"}} className={"bg-nav"}>
        <Container>
            <Navbar sticky="top"  style={{display:"flex", alignItems:"bottom"}} variant="dark" expand="md" bg="bg-nav">
                <Navbar.Brand onClick={() => handleClick(0)} style={{paddingLeft:"1%"}}><span className={"brand-color brand-size"}>Memory On: Listen and then Respond</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav>
                        <NavDropdown title={<span className={"text-color text-size"}>About the project</span>}>
                            <NavDropdown.Item onClick={() => handleClick(1)}>Brainstorm</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleClick(2)}>Generations</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleClick(3)}>Two triads</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleClick(4)}>Exhibit sketch</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href={'/silent'}><span className={"text-color text-size"}>Silent triad</span></Nav.Link>
                        <Nav.Link href={'/millennial'}><span className={"text-color text-size"}>Millennial triad</span></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    </Row>
    )
}

export default NavBar