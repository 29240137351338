import React from 'react';
import Axios from 'axios';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";


//import functions

//pages
import Index from './pages/Index/index'
import Profile from './pages/Profile/profile'
import Header from './pages/Header/header'
import Admin from './pages/Admin/admin'
import SilentTriad from "./pages/SilentTriad/SilentTriad";
import MillennialTriad from "./pages/MillennialTriad/MillennialTriad"

//development
// Axios.defaults.baseURL = 'http://localhost:8080';
//production
Axios.defaults.baseURL = 'https://memoryon.horizontalthinking.net';
function App() {
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Index/>}/>
                <Route path="/profile/:profile_id" element={<Profile/>}/>
                <Route path ="/profile/:profile_id/header/:header_id" element={<Header/>}/>
                <Route path="/admin" element={<Admin/>}/>
                <Route path="/silent" element={<SilentTriad/>}/>
                <Route path="/millennial" element={<MillennialTriad/>}/>
            </Routes>
        </Router>
    )


}

export default App;
