import React, {useEffect, useState} from 'react';
import { Container, Table, Button, Row, Form} from 'react-bootstrap';
import Axios from "axios";
import './admin.css'
import { BsTrash} from "react-icons/bs";
function Admin(){
    const [comments, setComments]= useState([])
    useEffect(() => {
        Axios.get('/api/comments')
            .then(response => {
                setComments(response.data.comments)
            });
    }, []);

    const publishResponse  =  (comment) => {
    Axios.put('api/comments/publish', {id: comment._id})
    }

    const unpublishResponse =   (comment) => {
        Axios.put('api/comments/unpublish', {id: comment._id}).then()
        }

    const deleteResponse = (comment) => {
        Axios.put('api/comments/delete', {id: comment._id})
    }

    const handlePublish =  (comment) => {
        publishResponse(comment);
        setTimeout(updateResponses, 250);


    }

    const handleDelete = (comment) => {
        deleteResponse(comment);
        setTimeout(updateResponses, 250);
    }

    const handleUnpublish =  (comment) => {
        unpublishResponse(comment);
        setTimeout(updateResponses, 250);
    }

    const updateResponses = () => {
        Axios.get('/api/comments')
            .then(response => {
                setComments(response.data.comments)
            });
    }
    //password protection
    const password = "admin1247"
    const [valid, setValid] = useState(false)
    const onSubmit = () => {
        if (formData === password){
            setValid(true)
        }
    }
    const [formData, setFormData] = useState(null)
    const handleChange = (event) => {
        setFormData(event.target.value)
    }
    if(!valid){
        return(
            <div style={{display: "flex", alignItems: "center", justifyContent:"center"}}>
                <div style={{width: "40%"}}>
                <Form >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{fontSize:"x-large",color:"black"}}>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Admin Password" onChange={handleChange}/>
                    </Form.Group>
                    <Button onClick={onSubmit} variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </div>
            </div>

                )
    }

    return(
        <Container>
            <h2>
                Submitted Responses:
            </h2>
            <br/>
            <div>
                    {comments.map((comment, i)=> (
                        !comment.published &&
                    <Table striped bordered hover key={i}>
                        <tbody>
                            <tr>
                                <td className={"fw-bold fixed-column"}>Profile ID</td>
                                <td>{comment.profile_id}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Header ID</td>
                                <td>{comment.header_id}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Submission Date</td>
                                <td>{comment.date}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Text</td>
                                <td>{comment.text}</td>
                            </tr>
                            <tr>
                                <td>
                                    <Row style={{justifyContent:"center"}}>
                                        <Button className={"btn-success-outline w-75"} variant={"danger"} style={{marginBottom:"10%"}} onClick={() => handlePublish(comment)}>
                                            Publish
                                        </Button>
                                        <Button className={"w-75"} variant={"danger"} onClick={() => handleDelete(comment)}>
                                            <BsTrash/>
                                        </Button>
                                    </Row>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>

                    ))}
            </div>
            <h2>
                Published Responses:
            </h2>
            <br/>
            <div>
                {comments.map((comment, i)=> (
                    comment.published &&
                        <Table striped bordered hover key={i}>
                            <tbody>
                            <tr>
                                <td className={"fw-bold fixed-column"}>Profile ID</td>
                                <td>{comment.profile_id}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Header ID</td>
                                <td>{comment.header_id}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Submission Date</td>
                                <td>{comment.date}</td>
                            </tr>
                            <tr>
                                <td className={"fw-bold"}>Text</td>
                                <td>{comment.text}</td>
                            </tr>
                            <tr>
                                <td>
                                <Row style={{justifyContent:"center"}}>
                                    <Button className={"btn-success-outline w-75"} variant={"danger"} style={{marginBottom:"10%"}} onClick={() => handleUnpublish(comment)}>
                                        Un-publish
                                    </Button>
                                    <Button className={"w-75"} variant={"danger"} onClick={() => handleDelete(comment)}>
                                        <BsTrash/>
                                    </Button>
                                </Row>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </Table>
                ))}
            </div>
        </Container>
    )
}

export default Admin