//card profile component
import {Card, Col} from "react-bootstrap";
import React from "react";
import './ProfileCard.css'
import {useNavigate} from "react-router-dom";

const ProfileCard = ({profile}) => {
    const navigate = useNavigate()
    const handleClick = (profile)=>{
        navigate(`/profile/${profile.profile_id}`, { state: profile })
    }
    return(
        <Col lg={6} md = {6} sm = {6}  className={"bottom-buffer d-flex justify-content-center"}>
            <Card onClick={ () => handleClick(profile)} style={{ cursor: "pointer", hover:"true", borderRadius:"30px"}}
                  className="cardh text-center h-100 bg-card hover-shadow">
                <Card.Body>
                    <Card.Title>{profile.name}</Card.Title>
                    <Card.Img className="rounded-circle" style={{width:"55%"}} src={require(`../../images/thumb${profile.profile_id}.jpg`)}/>
                    {profile.dob ?
                        <Card.Subtitle style={{marginTop: "20px"}}>{profile.dob} - {profile.dod}</Card.Subtitle> :
                        <Card.Subtitle style={{marginTop: "20px", visibility: "hidden"}}>""</Card.Subtitle>
                    }
                    <div className="flexible">
                        {profile.desc}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
};

export default ProfileCard