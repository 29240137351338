import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Container, Col, Row, ListGroup, Image, Card, Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import { FaHome } from 'react-icons/fa';
import "./profile.css"

function Profile(){
    const {state} = useLocation()
    const profile = state;
    const navigate = useNavigate()
    function handleClick(headerIdx){
        navigate(`/profile/${profile.profile_id}/header/${headerIdx}`, { state: {profile: profile, header: headerIdx} })
    }
    const silentTriad = ["G.I. Generation", "Silent Generation", "Baby Boomer Generation"]
    const handleHomeClick = () =>{
        if(silentTriad.includes(profile.generation)){
            navigate('/silent')
        }
        else{
            navigate('/millennial')
        }
    }


    return(
        <Container className={""}>
            <Row>
                <Col>
                    <h1 style={{color:"#6f92ba"}}>{profile.name}</h1>
                </Col>
            </Row>
            <Row>
                <Col style={{height:"50%"}}>
                    <Image src={require(`../../images/${profile.profile_id}.jpg`)} style={{maxWidth: "100%", maxHeight:"600px"}}/>
                    <p style={{color:"#6f92ba", fontWeight:"bold"}}>{profile.bio}</p>
                </Col>
                <Col>
                    <div className="topbuffer">
                        <ListGroup>
                            {profile.headers.map((header, i)=> (
                                <Card key={i} onClick={ () => handleClick(i)}
                                       className={"cardstyle"}
                                >
                                    <Card.Text>{header.title}</Card.Text>
                                </Card>
                            ))}
                        </ListGroup>
                        <a href={require(`../../full_stories/${profile.profile_id}.pdf`)} target="_blank" styles={{paddingLeft: "1000px"}}>Read Full Story</a>
                    </div>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Return to triad
                            </Tooltip>
                        }
                        placement="left"
                    >
                        <Button className={"btn-outline fixed-button-profile"} variant={"color"} style={{marginLeft:"50%"}} onClick={handleHomeClick}>
                            <FaHome/>
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile