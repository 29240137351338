import {Card, Row} from "react-bootstrap";
import React from "react";
import ProfileCard from '../ProfileCard/ProfileCard'

const GenerationCard = ({profiles}) => {
    if(profiles.length === 0){
        return <></>
    }
    return(
        <Card className="text-center h-100 bottom-buffer bg-gen">
            <Card.Title className="top-buffer">{profiles[0].generation}</Card.Title>
            <Card.Body>
                <Row>
                    {profiles.map((profile, i) =>(
                        <ProfileCard key = {i} profile={profile}/>
                    ))}
                </Row>
            </Card.Body>
        </Card>
    )
};

export default GenerationCard