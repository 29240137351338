import React from 'react';
import SlideCarousel from "../../components/SlideCarousel/SlideCarousel";
import NavBar from '../../components/NavBar/NavBar'
import {useLocation} from "react-router-dom";
import {Row} from 'react-bootstrap'
import './index.css'

const About = () =>{
    const {state} = useLocation()
    return(
        <div>
            <NavBar/>
            <Row>
                <SlideCarousel idx={state || 0}/>
            </Row>

        </div>)
}

export default About
